@import "../../../styles/variables";

.root {
    margin-top: 20px;
}

.box {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        margin: 10px;
    }
}
