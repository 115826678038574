@import "../../styles/variables";

.photoBox {
    width: 120px;
    height: 120px;
    border: 5px solid #FFFFFF;
    box-shadow: inset 0 0 3px 7px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    border-radius: 24px;
    position: relative;

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 18px;
    }

    input {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
    }
}

.addPhoto {
    border-radius: 38px;
    background-color: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15), inset 0 0 3px 7px rgba(0, 0, 0, 0.05);

    svg {
        width: 64px;
        height: 64px;
        path {
            stroke: $white;
        }
    }
}

.pencil {
    width: 36px;
    height: 36px;
    display: flex;
    background-color: $white;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 1;
    border-radius: 50%;

    svg {
        fill: $green;
        width: 16px;
        height: 16px;
    }
}

.flag {
    position: absolute;
    right: -4px;
    top: -4px;
    width: 39px;
    height: 39px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 20px;

    img {
        width: 19px;
        height: 14px;
    }
}

.delete {
    position: absolute;
    right: -15px;
    bottom: -15px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;

    svg {
        width: 20px;
        height: auto;

        path {
            stroke: $red;
        }
    }
}
