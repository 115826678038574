@import "../../styles/variables";

.wrap {
    font-size: 22px;
    line-height: 27px;
    color: $darkBlue;
    font-weight: 700;
    text-align: center;

    input {
        width: 100%;
        height: 60px;
        background: $white;
        border: 2px solid $lightGreen2;
        border-radius: 14px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $darkBlue;
        display: flex;
        align-items: center;
        padding: 0 10px;

        &:focus {
            border-color: $lightGreen3;
            outline: none;
        }
    }
}

.questionBox {
    display: flex;
    justify-content: center;
    width: 100%;

    p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        max-width: 200px;
        width: 100%;
    }
}

.btnBox {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 20px 20px 20px;
}

.accept {
    width: 100%;
    margin-top: 15px;
}

.err {
    margin-top: 12px;
    color: $red;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-weight: 400;
    display: inline-flex;
    justify-content: left;
    width: 100%;

    svg {
        width: 16px;
        height: auto;
        margin-right: 8px;
    }
}
