@import "../../styles/variables";

.countDown {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 15;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 140px;
    letter-spacing: 1.5px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    display: none;

    &.visible {
        display: flex;
    }

    svg {
        width: 80px;
        height: auto;
        fill: $white;
    }
}

