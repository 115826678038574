@import "../../../styles/variables";

.wrap {
    margin-top: 30px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;

    img {
        width: 180px;
        height: 172px;
        display: block;
    }

    button {
        width: 100%;
        margin-top: -40px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray3;
        margin: 15px 0 0;
        text-align: center;
        max-width: 300px;
    }
}
