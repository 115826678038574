@import "../../../styles/variables";

.info {
    text-align: center;
    margin-top: 30px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    p {
        margin-bottom: 30px;
    }

    img {
        width: 120px;
        height: auto;
    }

    @media #{$xxs} {
        img {
            width: 180px;
            height: 172px;
        }
    }
}

.askBox {
    margin-top: 20px;

    p {
        text-align: center;
        margin-bottom: 14px;
    }

    &.spaceTop {
        margin-top: 50px;
    }
}
