@import "../../styles/variables";

.wrap {
    font-size: 18px;
    line-height: 24px;
    color: $darkBlue;
    font-weight: 700;

    header {
        display: flex;
        width: 100%;
        padding-top: 10px;
        padding-right: 10px;
    }

    h3 {
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        margin: 3px 0 0;
        color: $darkBlue;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media #{$xxs} {
        font-size: 22px;
        line-height: 27px;

        h3 {
            font-size: 22px;
        }
    }
}

.back {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: -5px;

    svg {
        path {
            stroke: $darkBlue;
        }
        width: 26px;
        height: auto;
    }
}

.round {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-bottom-left-radius: 100% 30%;
    border-bottom-right-radius: 100% 30%;
    min-height: 380px;
    background-image: linear-gradient(180deg, rgba(225, 245, 255, 0.5) 31.66%, rgba(143, 220, 255, 0.5) 90.56%);
}
