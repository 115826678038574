@import "../../styles/variables";

.langs {
    background: $white;
    border-radius: 24px;
    display: flex;

    button {
        width: 80px;
        height: 50px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;
        cursor: pointer;
    }

    img {
        width: 28px;
        height: 22px;
    }

    .active {
        width: 80px;
        border: 4px solid #10CD75;
    }
}

