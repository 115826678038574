@import "../../styles/variables";

.curve {
    min-height: 260px;
    //position: relative;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        background-image: linear-gradient(180deg, rgba(225, 245, 255, 0.5) 31.66%, rgba(143, 220, 255, 0.5) 90.56%);
        height: 100%;
    }

    &::after {
        content: '';
        border-top-left-radius: 70% 100%;
        border-top-right-radius: 70% 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        background-color: $mainBg;
        height: 20%;
    }
}

