@import "../../styles/variables";

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 16;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    position: relative;
    background-color: $white;
    width: 250px;
    padding: 15px 54px 13px 15px;
    min-height: 80px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    color: $black3;
    line-height: 22px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.img {
    position: absolute;
    top: -35px;
    right: -35px;
}

.btn {
    position: absolute;
    left: -16px;
    top: -16px;
    background: $green2;
    border: 2px solid $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 12px;
        height: auto;

        path {
            stroke: $white;
        }
    }
}

