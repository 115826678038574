@import "../../../styles/variables";

.wrap {
    padding-bottom: 200px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.cover {
    width: 100%;
    height: 200px;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    img {
        height: 180px;
        object-fit: contain;
        border-radius: 4px;
    }

    button {
        display: none;
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        padding: 0;
        background: $white;
        border: 0;

        svg {
            width: 20px;
            height: auto;
            path {
                stroke: $darkBlue;
            }
        }
    }

    &.fullScreen {
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 13;
        background-repeat: no-repeat;
        background-size: 100%;

        img {
            transform: rotate(90deg);
            height: 90vw;
            max-width: initial;
            width: 90vh;
            display: block;
        }

        button {
            display: block;
        }
    }
}

.text {
    padding: 10px 20px 20px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: $black;
}

.progress {
    padding: 12px 6px 12px 3px;
    display: flex;
    position: relative;

    > div {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background-color: #E1F3D3;
    }

    > span {
        display: block;
        width: 100%;
        height: 8px;
        background: $lightGreen;
        margin-left: 5px;
    }

    .active {
        background-color: #A7BB96;
    }

    .has {
        background-color: $bluePure;
    }

    .err {
        background-color: $red;
    }
}


.recordBox {
    height: 88px;
    width: 100%;
    position: fixed;
    background-color: $white;
    padding: 10px;
    left: 0;
    bottom: 0;
    z-index: 2;
}

.recordMainBtns {
    display: flex;
    align-items: center;
    justify-content: center;

    .recordBtn {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;

        svg {
            width: 20px;
            height: auto;
            margin-right: 20px;

            path {
                fill: $white;
            }
        }
    }

    .hideBtn {
        visibility: hidden;
        z-index: -1;
        position: relative;
    }

    .recBtns {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
            width: 80px;

            &:first-child {
                margin-right: 15px;
            }
        }
    }

    .audioBtn {
        svg {
            fill: $white;
            width: 25px;
            height: auto;
        }
    }
}

.recordingBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 32px;
        height: 44px;
    }

    .stopBtn {
        width: 160px;

        > span {
            width: 22px;
            height: 22px;
            background-color: $white;
            box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
            border-radius: 2px;
            margin-right: 20px;
        }
    }
}
