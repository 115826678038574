@import "../../../../styles/variables";

.siderOpened {
    overflow: hidden;
    max-height: 80vh;
}

.navBtn {
    position: fixed;
    width: 60px;
    height: 60px;
    left: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 5;

    svg {
        width: 20px;
        height: auto;
    }
}

.book {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 16px;

    img {
        width: 100px;
        height: 120px;
        object-fit: contain;
    }

    @media #{$xxs} {
        img {
            width: 139px;
            height: 160px;
        }
    }
}

.addRecordButtonBox {
    padding-bottom: 20px;
    text-align: center;

    h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $darkBlue;
        margin: 0 0 20px;
    }
}

.addRecBtnBorder {
    border: 14px solid $lightGreen2;
    border-radius: 40px;
    display: inline-block;

    @media #{$xxs} {
        border: 18px solid $lightGreen2;
        border-radius: 50px;
    }
}

.addRecBtn {
    width: 110px;
    height: 110px;
    border: 5px solid #FFFFFF;
    box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
    border-radius: 24px;
    position: relative;
    background-color: $green;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 50px;
        height: auto;

        path, line {
            stroke: $white;
        }

        path:nth-child(3) {
            fill: $white;
        }
    }

    @media #{$xxs} {
        width: 130px;
        height: 130px;
    }
}
