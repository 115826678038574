@import "variables";
@import "reset";

/* apply a natural box layout model to all elements, but allowing components to change */
*, *:before, *:after {
    box-sizing: border-box;
}

html {
    height: -webkit-fill-available;
}

body {
    margin: 0;
    background: $mainBg;
    font-family: $rubik;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}
