@import "../../styles/variables";

.btn {
    position: absolute;
    z-index: 11;
    left: 10px;
    top: 10px;
    padding: 10px;
    display: block;
    background-color: transparent;
    border: 0;
    line-height: 1;

    svg {
        width: 18px;
        height: 18px;

        path {
            stroke: $darkBlue;
        }
    }
}

.sideNav {
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-color: $mainBg;
    z-index: 12;
    padding: 0 22px;
    transition: left 0.7s ease;

    header {
        display: flex;
        padding-top: 12px;
        margin-bottom: 70px;
        padding-right: 10px;
    }

    &.open {
        left: 0;
    }
}

.logo {
    width: 100%;
    text-align: center;
    padding-left: 60px;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: $darkBlue;
        margin-bottom: 35px;
        display: flex;
        align-items: center;
        width: 100%;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 20px;

            path {
                stroke: $darkGreen;
            }
        }

        span {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 20px;
            border: 2px solid $grayCB;
            border-radius: 50%;
        }
    }
}
