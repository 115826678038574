@import "../../styles/variables";

.saving {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 15;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    letter-spacing: 1.5px;
    font-weight: 700;
    text-transform: uppercase;

    svg {
        width: 90px;
        height: auto;
        margin-left: 40px;
        margin-top: -10px;
    }
}
