@import "../../styles/variables";

.curve {
    min-height: 140px;

    @media #{$xxs} {
        min-height: 180px;
    }
}

.backBtn {
    position: fixed;
    width: 60px;
    height: 60px;
    left: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 3;

    svg {
        width: 24px;
        height: auto;

        path {
            stroke: $darkBlue;
        }
    }
}
