@import "../../styles/variables";

.burgerWhite {
    background-color: $white !important;
}

.btn {
    position: fixed;
    z-index: 11;
    left: 10px;
    top: 10px;
    padding: 10px;
    display: block;
    background-color: transparent;
    border: 0;
    line-height: 1;

    svg {
        width: 18px;
        height: 18px;

        path {
            stroke: $darkBlue;
        }
    }
}
