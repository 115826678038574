// Colors

$white: #fff;
$black: #000;

$black3: #333333;

$mainBg: #f2fde8;
$green: #10CD75;
$green2: #0FC470;
$darkGreen: #219653;
$lightGreen: #E1F3D3;
$lightGreen2: #DAEECA;
$lightGreen3: #8CAA76;
$lightGreen4: #9FBF87;


$red: #FF5555;

$darkBlue: #00446B;
$blue: #189DEA;
$bluePure: #0F8AD1;
$blueLight: #23AFFF;
$darkBlue2: #0682C9;

$gray: #B0B0B0;
$grayCB: #CBCBCB;
$gray3: #333;


// Fonts
$rubik: 'Rubik', sans-serif;

$xxs: 'only screen and (min-width: 374px)';
$xs: 'only screen and (min-width: 480px)';
