@import "../../styles/variables";

.mini {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    display: none;
    position: fixed;
    z-index: 2;
    width: 100%;
    background: $mainBg;
    left: 0;
    top: 0;

    &.showMini {
        display: flex;
    }

    .photo {
        width: 250px;
        height: 250px;
        border-width: 10px;
    }

    .audio {
        //display: none;
        margin-top: 20px;
    }

    .pulseBox {
        position: relative;
        padding: 34px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50px;
            background-color: $lightGreen2;
        }

        button {
            background: transparent;
            padding: 0;
            margin: 0;
            border: 0;
            position: relative;

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                fill: $white;
                z-index: 1;
                width: 50px;
                height: auto;
            }
        }

        &.playing:before {
            animation: breathing 1.2s ease-out infinite normal;
        }
    }

    @keyframes breathing {
        0% {
            transform: scale(0.89);
        }

        50% {
            transform: scale(1.02);
        }

        100% {
            transform: scale(0.89);
        }
    }
}


.photoBox {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    > div {
        border: 18px solid $lightGreen2;
        border-radius: 50px;
    }
}

.sliderBox {
    margin-top: 25px;
}

.audioTimeBox {
    margin-top: 30px;
}

.audioControls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .pausePlay {
        width: 119px;
        height: 119px;
        border: 5px solid $white;
        background: url("../../assets/light-blu-ellipce.png") no-repeat 13px 3px $blueLight;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;

        svg {
            fill: $white;
        }
    }

    .secBtn {
        width: 98px;
        height: 98px;
        background: url("../../assets/blue-ellipce.png") no-repeat 9px 2px $darkBlue2;
        border: 5px solid $white;
        box-shadow: 0 4px 10px #B9D3A4;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: $white;
        text-transform: uppercase;

        svg {
            width: 20px;
            height: auto;
            margin-top: 3px;
        }
    }
}

.pingSwitcher {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 5;

    button {
        border: 1px solid $lightGreen2;
        border-radius: 16px;
        background: transparent;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        height: 32px;
        display: flex;
        padding: 0;
        background: $lightGreen2;
    }

    span {
        width: 50%;
        padding: 0 8px;
        display: flex;
        align-items: center;
        height: 100%;
        border-radius: 14px;
    }

    svg {
        width: 14px;
        height: auto;
        fill: #666;
    }

    span:first-child  svg{
        width: 16px;
    }

    .active {
        background: $lightGreen4;

        svg {
            fill: $darkBlue;
        }
    }
}
