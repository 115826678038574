@import "../../styles/variables";

.narratorsBox {
    padding-bottom: 20px;

    h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $darkBlue;
        margin: 0 0 20px;
    }
}

.narrators {
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
        margin-left: 10px;
        margin-bottom: 10px;
    }
}

.border {
    border: 14px solid $lightGreen2;
    border-radius: 40px;

    .narrator {
        width: 110px;
        height: 110px;
    }

    @media #{$xxs} {
        border: 18px solid $lightGreen2;
        border-radius: 50px;

        .narrator {
            width: 130px;
            height: 130px;
        }
    }
}
