@import "../../../styles/variables";

.wrap {
    padding: 12px 30px;

    h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $darkBlue;
        margin: 0 0 10px;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 68, 107, 0.87);
        text-align: center;
        margin-bottom: 25px;
    }

    a {
        text-decoration: none;
    }
}

.cover {
    display: flex;
    justify-content: center;

    img {
        width: 175px;
    }
}

.avatars {
    display: flex;
    justify-content: center;
    margin-top: 18px;

    > div {
        width: 136px;
        height: 136px;

        &:first-child {
            margin-right: 18px;
        }
    }
}

.uploadComplete {
    margin-top: 55px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkBlue;

    svg {
        fill: $green;
        width: 23px;
        height: auto;
        margin-right: 20px;
    }
}

.btn {
    width: 100%;
    margin-top: 45px;
}

.progressBox {
    margin-top: 30px;

    .progress {
        background: $lightGreen;
        height: 8px;
        border-radius: 4px;
        position: relative;

        span {
            position: absolute;
            border-radius: 4px;
            height: 8px;
            left: 0;
            top: 0;
            background: $green;
        }
    }

    .nums {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 68, 107, 0.87);
    }
}


