@import "../../styles/variables";

.sideNav {
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background-color: $mainBg;
    z-index: 10;
    padding: 0 20px 100px 20px;
    transition: left 0.5s ease;

    &.open {
        left: 0;
    }
}
