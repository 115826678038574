@import "../../styles/variables";

.langs {
    width: 32px;
    height: 30px;
    position: relative;
    padding-top: 6px;

    .selected {
        position: relative;
        width: 60px;

        button {
            background-color: transparent;
            border: 0;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
        }

        img {
            width: 30px;
            margin-right: 6px;
        }

        svg {
            width: 11px;
            height: auto;
        }
    }

    .expand {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        display: none;

        &.open {
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            margin-top: 10px;
        }

        img {
            width: 30px;
        }
    }
}
