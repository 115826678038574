@import "variables";

.error {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $red;
    font-size: 18px;
    line-height: 1.4;
    padding: 20px;
    font-weight: bold;

    svg {
        width: 70px;
        height: auto;
        fill: $red;
        margin-bottom: 30px;
    }
}

.loading {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;

    svg {
        fill: $green;
    }
}

.photoBoxBorder {
    border: 18px solid $lightGreen2;
    border-radius: 50px;

    .narrator {
        width: 130px;
        height: 130px;
    }
}
