@import "../../styles/variables";

.ascRecBtn {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;

    span {
        display: block;
        font-size: 12px;
        line-height: 18px;
    }
}

.or {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 12px;
}

.fieldBox {
    background: #fff;
    border: 2px solid #DAEECA;
    border-radius: 14px;
    font-weight: 700;
    font-size: 12px;
    color: #00446B;
    display: flex;
    align-items: center;
    padding: 0 6px;

    input {
        height: 40px;
        border: 0;
        width: 100%;
        border-radius: 10px;
        padding-right: 10px;

        &:focus {
            outline: none;
        }
    }

    button {
        height: 34px;
        border-radius: 10px;
        background-color: $green;
        border: 1px solid $white;
        color: $white;
        font-size: 14px;
    }
}
