@import "../../../styles/variables";

.carouselContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $lightGreen;
    position: relative;
    padding-bottom: 37px;
    padding-top: 10px;
}

.carouselWrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carouselContentWrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carouselContent {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */

    &::-webkit-scrollbar,
    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }

    &.showMultiple {
        width: calc(100% / 3);
    }
}

.leftArrow,
.rightArrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 120px;
    background: $green;
    border: 5px solid $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftArrow {
    left: 0;
    border-top-right-radius: 130px;
    border-bottom-right-radius: 130px;
    border-left: 0;

    svg {
        margin-right: 6px;
    }
}

.rightArrow {
    right: 0;
    border-top-left-radius: 130px;
    border-bottom-left-radius: 130px;
    border-right: 0;

    svg {
        margin-left: 6px;
    }
}

.imgBox {
    padding: 8px;
    min-height: 86px;
    display: flex;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        border: 2px solid $lightGreen4;
        background-color: $lightGreen4;
        border-radius: 4px;
    }

    &.active {
       padding: 0;
    }
}

.text {
    text-align: center;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: $lightGreen3;
}

