@import "../../styles/variables";

.btn {
    height: 68px;
    padding: 0 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 5px solid $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25), inset 0 0 3px 7px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background-color: $green;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: $white;

    &:disabled {
        background-color: $gray;
    }
}

.fullwidth {
    width: 100%;
}

.red {
    background-color: $red;
}

.blue {
    background-color: $blue;
}

.loader {
    svg {
        width: 30px;
        height: auto;
        fill: $white;
    }
    margin-right: 10px;
    line-height: 1;
}
