@import "../../../styles/variables";

.wrap {
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding-bottom: 120px;
}

.back {
    position: absolute;
    left: 10px;
    top: 10px;
    background: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        width: 25px;
        height: auto;

        path {
            stroke: $darkBlue;
        }
    }
}

.approveBox {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 20px;

    a {
        text-decoration: none;
    }

    button {
        width: 312px;
    }
}

