@import "../../styles/variables";

.tooltip {
    position: relative;
    display: inline-block;

    &.showOnHover:hover {
        .message {
            display: block;
        }
    }
}

.message {
    display: none;
    position: absolute;
    background-color: $white;
    width: 224px;
    padding: 11px 54px 13px 11px;
    min-height: 80px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    color: $black3;
    line-height: 22px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 5;
    text-align: left;

    &:before {
        content: "";
        width: 24px;
        height: 24px;
        transform: rotate(45deg) translate(-50%, 0);
        background-color: $white;
        position: absolute;
        left: 50%;
        top: 0;
    }

    img {
        position: absolute;
        top: -35px;
        right: -35px;
    }

    &.visible {
        display: block;
    }
}

.bottom {
    left: 50%;
    bottom: -14px;
    transform: translate(-50%, 100%);
}

.top {
    left: 50%;
    transform: translate(-50%, -100%);
    top: 0;

    &:before {
        top: initial;
        bottom: -20px;
    }
}


.btn {
    position: absolute;
    left: -16px;
    top: -16px;
    background: $green2;
    border: 2px solid $white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 12px;
        height: auto;

        path {
            stroke: $white;
        }
    }
}
