@import "../../../styles/variables";

.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 135px;
    position: relative;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.title {
    &, &.title {
        margin: 26px 58px 0 58px;
        max-width: 300px;
        min-height: 80px;
    }
}

.cover {
    margin-top: 20px;
    margin-bottom: 12px;
    position: relative;
    padding-left: 68px;

    > img {
        width: 217px;
        height: 289px;
        object-fit: cover;
    }
}

.userPhoto {
    position: absolute !important;
    bottom: 30px;
    left: 0;
}

.actionBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 40px 32px 25px 32px;
}

.addPhotoBox {
    margin-bottom: 12px;
}

.txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $darkBlue;
    margin-bottom: 8px;
}
