@import "../../../styles/variables";

.preview {
    width: 200px;
    background-color: $mainBg;
    background-image: url("../../../assets/preview-bg.png");
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 150px;
    border: 9px solid $white;
    border-top: 0;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;

    .imgs {
        position: relative;
        width: 93px;
        margin: 0 auto;
    }

    .cover {
        width: 93px;
        height: 100px;
        object-fit: cover;
        position: relative;
        filter: drop-shadow(0px 1.99124px 6.96933px rgba(0, 0, 0, 0.25));
    }

    .photo {
        width: 68px;
        height: 68px;
        border-width: 2px;
        position: absolute;
        left: -30px;
        bottom: -25px;
    }

    .langs {
        margin-top: 45px;
        border-radius: 12px;
        width: 80px;
        margin-right: auto;
        margin-left: auto;

        button {
            width: 40px;
            height: 24px;
            border-radius: 12px;
            border-width: 2px;
        }

        img {
            width: 14px;
            height: 12px;
        }
    }

    .btn {
        height: 34px;
        width: 100%;
        border-radius: 10px;
        border-width: 2px;
        font-size: 8px;
        margin-top: 10px;
    }
}

.previewTitle {
    width: 250px;
    margin: 12px auto 25px;
    text-align: center;
}

.addPhotoBox {
    text-align: center;
}

.addPhoto {
    width: 128px;
    height: 128px;
}

.txt {
    margin-top: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: rgba(0, 68, 107, 0.87);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: initial;
    width: 100%;

    svg {
        width: 25px;
        height: auto;
        margin-right: 13px;
    }
}

.manageRecords {
    width: 100%;
    margin-top: 25px;
}
