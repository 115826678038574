@import "../../styles/variables";

.title {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: $darkBlue;
    text-align: center;
    margin: 0;
}

