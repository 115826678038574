@import "../../styles/variables";

.box {
    svg {
        width: 32px;
        height: 44px;

        path {
            fill: $red;
            animation: ease pulse 2s infinite;
        }
    }

    &, .recordTime {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .recordTime {
        width: 58px;
        height: 26px;
        border-radius: 34px;
        background: $red;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.25px;
        color: $white;
        margin-left: 10px;
    }
}

@keyframes pulse {
    0% {
        fill: $red;
    }
    50% {
        fill: #ff0808;
    }
    100% {
        fill: $red;
    }
}
